import ApiService from "./api.service.js";
import URLS from "./url.service.js";
import util from "util";
let isDev = false;
let firstTimeOnboard = false;
let tryDownload = 0;

const MainService = {
  // get last job info of manual sync event
  getLastJobInfo(params) {
    if (isDev) {
      return Promise.resolve(
        {
          "data": {
            "items": [
              {
                "start_time": "2021-11-29T04:28:17.212Z",
                "end_time": "2021-11-29T04:28:17.212Z",
                "event_type": "FULL_INVENTORY_UPDATES",
                "trace_id": [
                  "myntra_ppmp_inventory_syncer.c546e85b-42cb-415b-95b6-81997b3d9227"
                ],
                "count": 1,
                "status": "cancelled"
              }
            ],
            "page": {
              "type": "number",
              "size": 1,
              "current": 1,
              "has_next": true,
              "item_total": 5
            }
          }
        }
      )
    }
    return ApiService.get(
      URLS.MARKETPLACE_LOGS(),
      { params }
    );
  },
  // 
  getCompanyConfig(companyId, channelId) {
    console.log("firstTimeonboard", firstTimeOnboard);
    if (isDev && !firstTimeOnboard) {
      return Promise.resolve({
        data: {
          merchant_id: "merchant id",
          secret_key: "secret key",
        },
      });
    } else if (firstTimeOnboard) {
      return Promise.resolve({
        data: {},
      });
    }
    return ApiService.get(URLS.MARKETPLACE_CONFIG(companyId, channelId), {});
  },
  getCompanyStatus() {
    if (isDev) {
      return Promise.resolve({
        data: {
          is_active: true,
          sync: {
            price: false,
            quantity: true,
          },
        },
      });
    }
    return ApiService.get(URLS.MARKETPLACE_STATUS(), {});
  },
  triggerResourceSync(body) {
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.post(URLS.RESOURCE_SYNC(), axiosOption);
  },
  triggerCustomSync(body) {
    let axiosOption = Object.assign(
        {},
        {
            data: body
        }

    );
    return ApiService.post(
        URLS.CUSTOM_SYNC(),
        axiosOption
    );
  },
  getLastSyncJobStatus(params) {
    if (isDev) {
      return Promise.resolve({
        status: "completed",
        eventType: "INVENTORY_RESET_UPDATES",
        totalItems: 10,
        logStatus: {
          success: 5,
          failed: 3,
          cancelled: 2
        }
      })
    }
    return ApiService.get(URLS.INV_RESET_STATUS(), { params })
  },
  getResourceSyncLastJobInfo(params, syncType) {
    if (isDev) {
      return Promise.resolve({
        data: {
          URL: "",
          company_id: 1,
          created_at: "2022-09-07T19:51:39.407Z",
          error_jobs: [],
          errors_occured: [],
          finished_jobs: [],
          jobs: ["6318f64b7e51982a299c241b"],
          status: "running",
          sync_type: "inventory",
          total_jobs: 1,
          updated_at: "2022-09-07T19:51:48.561Z",
          __v: 0,
          _id: "6318f64b7e51982a299c2419",
        },
      });
    }
    let axiosOption = Object.assign({}, {});
    const resourceSyncURL = util.format(
      URLS.RESOURCE_SYNC_LAST_JOB(),
      syncType
    );
    console.log("resourceSyncURL", resourceSyncURL);
    return ApiService.get(resourceSyncURL, axiosOption);
  },
  handleCancelJobs(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "1233",
        },
      });
    }
    let axiosOption = Object.assign({}, { data: params });
    return ApiService.put(URLS.LOCATION_CANCEL_JOBS(), axiosOption);
  },

  productCancelJobs(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "1233",
        },
      });
    }
    let axiosOption = Object.assign({}, { data: params });
    return ApiService.put(URLS.PRODUCTS_CANCEL_JOBS(), axiosOption);
  },

  updateMarketplaceStatus(body) {
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.put(URLS.MARKETPLACE_STATUS(), axiosOption);
  },
  updateKeyInDb(params) {
    let axiosOption = Object.assign(
      {},
      {
        data: params
      }
    );
    return ApiService.put(URLS.CURRENT_COMPANY_STATUS(), axiosOption)
  },
  createMarktplaceConfig(config, queryParams) {
    if (isDev) {
      return Promise.resolve({
        data: {},
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: config,
        params: queryParams,
      }
    );
    return ApiService.post(URLS.MARKETPLACE_CONFIG(), axiosOption);
  },
  checkIfFirstTimeOnBoard() {
    if (isDev) {
      return true;
    }
    return ApiService.get(
      URLS.IS_COMPANY_NEW(),
      {}
    );
  },
  updateMarktplaceConfig(config, queryParams) {
    console.log("body", config, "queryParams", queryParams);
    if (isDev) {
      return Promise.resolve({
        data: {},
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: config,
        params: queryParams,
      }
    );
    return ApiService.put(URLS.MARKETPLACE_CONFIG(), axiosOption);
  },
  getInventorySyncConfig() {
    if (isDev) {
      return Promise.resolve({
        data: {
          quantity: true,
          price: true,
        },
      });
    }
    return ApiService.get(URLS.MARKETPLACE_SYNC_CONFIG(), {});
  },
  updateInventorySyncConfig(config, params) {
    let axiosOption = Object.assign(
      {},
      {
        data: config,
        params,
      }
    );
    return ApiService.put(URLS.MARKETPLACE_SYNC_CONFIG(), axiosOption);
  },
  fetchStoreStats() {
    if (isDev) {
      return Promise.resolve({
        data: {
          total: 4,
          mapped: 2,
          active: 2,
        },
      });
    }
    return ApiService.get(URLS.LOCATION_STATS(), {});
  },
  fetchStoresLastJobStatus() {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "6162f8fafd613a398e64b0ba",
          status: "completed",
          type: "download",
          payload: {},
          URL: "",
          finished_count: 10,
          error_count: 5,
        },
      });
    }
    return ApiService.get(URLS.LOCATION_LAST_JOB(), {});
  },
  fetchStoreMapping(params) {
    if (isDev) {
      
      return Promise.resolve({
        data: {
          items: [
            {
              uid: 55,
              name: "Maryellen Congleton",
              address: {
                state: "KARNATAKA",
                address1: "NO",
                lat_long: {
                  type: "Point",
                  coordinates: [77.5483533, 12.9565222],
                },
                address2: "NO",
                pincode: 560026,
                country: "INDIA",
                city: "BANGALORE URBAN",
              },
              store_type: "high_street",
              // "store_code": "d6b6b165-ed98-4827-b537-7b7b60cc45de",
              store_code: "ADIL032",
              is_active: true,
              quantity_buffer: 0,
              enabled: true,
              _id: "60e59d8d02036de50e54c2dc",
              store_id: 55,
              marketplace_store_id: "ADIL032",
              code: "d6b6b165-ed98-4827-b537-7b7b60cc45de",
              stage: "verified",
              toggle_activation: false
            },
            {
              uid: 56,
              name: "Sabrina Wallis",
              address: {
                state: "TAMIL NADU",
                address1: "NO",
                lat_long: {
                  type: "Point",
                  coordinates: [76.944141, 10.9990845],
                },
                address2: "NO",
                pincode: 641001,
                country: "INDIA",
                city: "COIMBATORE",
              },
              store_type: "high_street",
              // "store_code": "dbb212a9-07aa-4f23-bb35-3516b8b4bf90",
              store_code: "okala123",
              is_active: true,
              quantity_buffer: 0,
              enabled: true,
              _id: "60e59d8d02036d514a54c2dd",
              store_id: 56,
              marketplace_store_id: "fsdsffs",
              code: "dbb212a9-07aa-4f23-bb35-3516b8b4bf90",
              stage: "verified",
              toggle_activation: false
            },
            {
              uid: 57,
              name: "David Olsin",
              address: {
                state: "MAHA",
                address1: "NO",
                lat_long: {
                  type: "Point",
                  coordinates: [1, 1],
                },
                address2: "NO",
                pincode: 400072,
                country: "INDIA",
                city: "MUMBAI",
              },
              store_type: "high_street",
              store_code: "13a4f407-1239-4cc1-b825-602da4b732d0",
              marketplace_store_id: "ADIL033",
              is_active: true,
              store_id: 57,
              quantity_buffer: 0,
              code: "13a4f407-1239-4cc1-b825-602da4b732d0",
            },
            {
              uid: 11595,
              name: "Bhau ka dukan",
              address: {
                state: "ARUNACHAL PRADESH",
                address1: "792104",
                lat_long: {
                  type: "Point",
                  coordinates: [96.8350999, 28.0359962],
                },
                address2: "SWDERT",
                pincode: 792104,
                country: "INDIA",
                city: "ANJAW",
              },
              store_type: "high_street",
              store_code: "sdfgffdws345",
              is_active: true,
              store_id: 11595,
              marketplace_store_id: "ADIL034",
              quantity_buffer: 0,
              code: "sdfgffdws345",
              stage: "verified",
            },
          ],
          page: {
            current: 1,
            has_next: true,
            item_total: 68,
            type: "number",
          },
        },
      });
    }
    return ApiService.get(URLS.MARKETPLACE_STORES(), { params });
  },
  downloadStoreMapping(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "1233",
        },
      });
    }
    let axiosOption = Object.assign({}, { data: params });
    return ApiService.post(URLS.DOWNLOAD_STORE_MAPPING(), axiosOption);
  },
  bulkUploadStoreMapping(storeMapping, isInventoryOrPriceJobTriggered) {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "1233",
        },
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: {
          store_mapping: storeMapping,
          isInventoryOrPriceJobTriggered
        },
      }
    );
    return ApiService.post(URLS.BULK_UPLOAD_STORE_MAPPING(), axiosOption);
  },
  getStoreExportUploadsdJobStatus(params) {
    console.log("status api", "called");
    if (isDev) {
      return Promise.resolve({
        data: {
          status: "completed",
          // store_wrong_creds:
          // {
          //     "0": "Invalid Location id or password.",
          //     "1": "Invalid Location id or password."
          // }
          // URL: "http://localhost:5058"
        },
      });
    } else if (isDev) {
      tryDownload++;
      return Promise.resolve({
        data: {
          status: "pending",
        },
      });
    }
    let axiosOption = Object.assign({}, { params });
    return ApiService.get(URLS.BULK_UPLOAD_STORE_JOB_STATUS(), axiosOption);
  },
  getStoreExportDownloadJobStatus(params) {
    if (isDev && tryDownload == 2) {
      tryDownload = 0;
      return Promise.resolve({
        data: {
          status: "completed",
          URL: "http://localhost:5058",
        },
      });
    } else if (isDev) {
      tryDownload++;
      return Promise.resolve({
        data: {
          status: "pending",
        },
      });
    }
    let axiosOption = Object.assign({}, { params });
    return ApiService.get(URLS.DOWNLOAD_STORE_JOB_STATUS(), axiosOption);
  },
  fetchProductsLastJobStatus() {
    console.log("called last job");
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: "6162f8fafd613a398e64b0ba",
          status: "completed",
          type: "upload",
          payload: {},
          URL: "",
          finished_count: 10,
          error_count: 5,
        },
      });
    }
    return ApiService.get(URLS.PRODUCT_LAST_JOB(), {});
  },
  fetchProductStats() {
    if (isDev) {
      return Promise.resolve({
        data: {
          total: 4,
          mapped: 2,
          active: 2,
        },
      });
    }
    return ApiService.get(URLS.PRODUCT_STATS(), {});
  },
  updateStoreMapping(storeMapping, isInventoryOrPriceJobTriggered) {
    if (isDev) {
      return Promise.resolve({
        store_mapping: [
          {
            store_id: 55,
            marketplace_store_id: "dssd12345678",
            enabled: true,
            meta: {},
            quantity_buffer: 10,
          },
        ],
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: {
          store_mapping: storeMapping,
          isInventoryOrPriceJobTriggered
          // store_mapping_enabled: true
        },
      }
    );
    return ApiService.put(URLS.MARKETPLACE_STORES(), axiosOption);
  },
  triggerInvUpdates(body) {
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.post(URLS.INVENTORY_UPDATES(), axiosOption);
  },
  traceProductInventory(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          items: [
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f1700ec45047b5b6bf892",
              event_type: "SKU_UPDATES",
              company_id: 2,
              myntra_ppmp_sku: "alankrit",
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:05:36.105Z",
              updated_at: "2021-10-19T19:05:36.105Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f17fcec4504bc326bf89a",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 0,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:09:48.407Z",
              updated_at: "2021-10-19T19:09:48.407Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f17fcec450453b36bf8a2",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              __v: 0,
              created_at: "2021-10-19T19:09:48.415Z",
              updated_at: "2021-10-19T19:09:48.415Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f1826ec4504f8ff6bf8aa",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:10:30.962Z",
              updated_at: "2021-10-19T19:10:30.962Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f1c0e694e0a81685a5fc6",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 0,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:27:10.222Z",
              updated_at: "2021-10-19T19:27:10.222Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f1c12694e0a63c75a5fce",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              __v: 0,
              created_at: "2021-10-19T19:27:14.636Z",
              updated_at: "2021-10-19T19:27:14.636Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f21dc3fb07ba9fa6eb780",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 0,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:51:56.114Z",
              updated_at: "2021-10-19T19:51:56.114Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f21f43fb07bd02c6eb788",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "DAKJDI3743892" },
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "asdfsgd",
              __v: 0,
              created_at: "2021-10-19T19:52:20.704Z",
              updated_at: "2021-10-19T19:52:20.704Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f21f53fb07b49a96eb790",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              __v: 0,
              created_at: "2021-10-19T19:52:21.188Z",
              updated_at: "2021-10-19T19:52:21.188Z",
            },
            {
              trace_id: [],
              status: "cancelled",
              _id: "616f22aa3fb07bfcb16eb798",
              event_type: "MYNTRA_SKU_UPDATES",
              company_id: 2,
              store_code: "ADIL039",
              store_id: 10,
              item_id: 75248137,
              inventory_id: "10_DAKJDI3743892",
              seller_identifier: "DAKJDI3743892",
              channel_identifier: { sku: "516859008004" },
              total_quantity: 50,
              sellable_quantity: 50,
              damaged_quantity: 0,
              committed_quantity: 0,
              not_available_quantity: 0,
              marked_price: 10000,
              effective_price: 10000,
              marketplace_store_id: "ST1",
              __v: 0,
              created_at: "2021-10-19T19:55:22.495Z",
              updated_at: "2021-10-19T19:55:22.495Z",
            },
          ],
          page: { current: 1, total: 2, has_next: false, total_item_count: 15 },
        },
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        // data: body
        params,
      }
    );
    return ApiService.get(URLS.TRACE_INVENTORY(), axiosOption);
  },
  uploadSKUMapping(body) {
    if (isDev) {
      return Promise.resolve({
        data: "done",
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.post(URLS.MARKETPLACES_ID_MAP(), axiosOption);
  },
  uploadBulkSKUMapping(body) {
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.post(URLS.MARKETPLACES_ID_MAP_BULK(), axiosOption);
  },
  getSKUMapping() {
    return ApiService.post(URLS.MARKETPLACES_ID_MAP(), {});
  },
  getMarketplaceIdentifiers() {
    return ApiService.get(URLS.MARKETPLACE_IDENTIFIERS(), {});
  },
  getArticleIdentifiers() {
    return ApiService.get(URLS.ARTICLE_IDENTIFIERS(), {});
  },
  downloadSKUMapping(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          task_id: 123,
        },
      });
    }
    let axiosOption = Object.assign({}, { data: params });
    return ApiService.post(URLS.DOWNLOAD_MAPPING(), axiosOption);
  },
  getExportDownloadJobStatus(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          status: "completed",
          URL:
            "https://fynd-platform-extension-assets-staging.s3.us-east-2.amazonaws.com/platform-extensions/myntra-ppmp/sku-map-export/myntra_ppmp_1_615c1435c65270f9a8b42e31.csv",
        },
      });
    }
    let axiosOption = Object.assign({}, { params });
    return ApiService.get(URLS.DOWNLOAD_JOB_STATUS(), axiosOption);
  },
  getMappedSkuList(params /* eslint-disable-line no-unused-vars*/) {
    if (isDev) {
      return Promise.resolve({
        data: {
          data: [
            {
              item_code: "SETSFYNDSTORE",
              uid: 7500211,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/1031_5G692BLKREDSET/1_1584448372829.jpg",
              ],
              name: "SetTEStProduct",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "101001100101",
                  size: "34X5",
                  marketplace_identifiers: {},
                  active: false,
                },
                {
                  seller_identifier: "SETSFYNDSTORE_OS",
                  size: "SX2, MX2, LX2 (6 PCS)",
                  marketplace_identifiers: {},
                  active: true,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "PRODUCTTESTING",
              uid: 7500212,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/85_TESTAJIJ/1_1595617980416.jpg",
              ],
              name: "PRODUCT_TESTING",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "PRODUCTTESTING_OS",
                  size: "SX2, MX2, LX2 (6 PCS)",
                  marketplace_identifiers: {
                    sku: "PRODUCTTESTING_OS",
                  },
                  active: true,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "IDENTIFIERTEST",
              uid: 7609934,
              images: [],
              name: "IDENTIFIERTEST",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "IDENTIFIERTEST",
                  size: "OS",
                  marketplace_identifiers: {
                    sku: "IDENTIFIERTEST",
                  },
                  active: true,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "BAKO@BLANC",
              uid: 73100,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@BLANC/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@BLANC/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@BLANC/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@BLANC/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@BLANC/5.jpg",
              ],
              name: "White Solid Casual Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "3596653461284",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "3596653461284",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461277",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "3596653461277",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461260",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "3596653461260",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461291",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "3596653461291",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461307",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "3596653461307",
                  },
                  active: false,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "CESPORTY@BLEUROI",
              uid: 73102,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@BLEUROI/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@BLEUROI/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@BLEUROI/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@BLEUROI/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@BLEUROI/5.jpg",
              ],
              name: "Blue Striped T-Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "3596654325271",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "3596654325271",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654325288",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "3596654325288",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654325295",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "3596654325295",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654325264",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "3596654325264",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654325257",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "3596654325257",
                  },
                  active: false,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "BAKO@GRIS",
              uid: 73101,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@GRIS/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@GRIS/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@GRIS/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_BAKO@GRIS/4.jpg",
              ],
              name: "Grey Solid Casual Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "3596653461338",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "3596653461338",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461321",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "3596653461321",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461314",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "3596653461314",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461345",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "3596653461345",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596653461352",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "3596653461352",
                  },
                  active: false,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "ABELONG@INDIGO12",
              uid: 73098,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_ABELONG@INDIGO12/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_ABELONG@INDIGO12/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_ABELONG@INDIGO12/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_ABELONG@INDIGO12/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_ABELONG@INDIGO12/5.jpg",
              ],
              name: "Indigo Melange T-Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "3596654299879",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "3596654299879",
                  },
                  active: true,
                },
                {
                  seller_identifier: "3596654299886",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "3596654299886",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654299893",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "3596654299893",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654299862",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "3596654299862",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654299855",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "3596654299855",
                  },
                  active: false,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "DAKNIT@GRIS",
              uid: 73111,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAKNIT@GRIS/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAKNIT@GRIS/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAKNIT@GRIS/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAKNIT@GRIS/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAKNIT@GRIS/5.jpg",
              ],
              name: "Grey Solid Casual Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "3596654289405",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "3596654289405",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654289412",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "3596654289412",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654289429",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "3596654289429",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654288996",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "3596654288996",
                  },
                  active: false,
                },
                {
                  seller_identifier: "3596654288989",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "3596654288989",
                  },
                  active: false,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "CESPORTY@JAUNE",
              uid: 73103,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@JAUNE/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@JAUNE/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@JAUNE/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@JAUNE/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_CESPORTY@JAUNE/5.jpg",
              ],
              name: "Yellow and Blue Striped T-Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "8904231507043",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "8904231507043",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231507036",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "8904231507036",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231507029",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "8904231507029",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231507050",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "8904231507050",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231507067",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "8904231507067",
                  },
                  active: true,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
            {
              item_code: "DAPOP2@OPTICALWHITE",
              uid: 73126,
              images: [
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAPOP2@OPTICALWHITE/1.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAPOP2@OPTICALWHITE/2.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAPOP2@OPTICALWHITE/3.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAPOP2@OPTICALWHITE/4.jpg",
                "https://hdn-1.fynd.com/media/pictures/tagged_items/original/44_DAPOP2@OPTICALWHITE/5.jpg",
              ],
              name: "White Patterned Casual Shirt",
              brand_uid: 44,
              articles: [
                {
                  seller_identifier: "8904231508958",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "8904231508958",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508941",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "8904231508941",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508934",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "8904231508934",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508965",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "8904231508965",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508972",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "8904231508972",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508958",
                  size: "L",
                  marketplace_identifiers: {
                    sku: "8904231508958",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508941",
                  size: "M",
                  marketplace_identifiers: {
                    sku: "8904231508941",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508934",
                  size: "S",
                  marketplace_identifiers: {
                    sku: "8904231508934",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508965",
                  size: "XL",
                  marketplace_identifiers: {
                    sku: "8904231508965",
                  },
                  active: true,
                },
                {
                  seller_identifier: "8904231508972",
                  size: "XXL",
                  marketplace_identifiers: {
                    sku: "8904231508972",
                  },
                  active: true,
                },
              ],
              brand: {
                id: 44,
                name: "celio",
              },
            },
          ],
          page: {
            current: 1,
            total: 242,
            type: "number",
            has_next: true,
            total_item_count: 2418,
          },
        },
      });
    }
    let axiosOption = Object.assign({}, { params });
    return ApiService.get(URLS.SKU_LIST(), axiosOption);
  },
  getSkuMappingsOfItem(itemId, marketplace, companyId) {
    let axiosOption = Object.assign({}, {});
    return ApiService.get(
      URLS.ITEM_MAPPINGS(itemId, marketplace, companyId),
      axiosOption
    );
  },
  getProducts(params) {
    let axiosOption = Object.assign({}, { params: params });
    return ApiService.get(URLS.PRODUCTS(), axiosOption);
  },
  fetchBrandsByCompany() {
    if (isDev) {
      return Promise.resolve({
        data: {
          brands: [
            {
              name: "celio",
              value: 44,
              brand_logo_url:
                "https://hdn-1.fynd.com/media/logo/brand/original/13995_d6059d098f8248c4a6f50ec151496137.jpg",
              brand_banner_url:
                "https://hdn-1.fynd.com/media/banner/brand/original/13996_ac2d726eca864ee8b82b180a68d94b1e.jpg",
              brand_banner_portrait_url:
                "https://hdn-1.fynd.com/media/banner_portrait/brand/original/13997_291169a4fb674d2295e033a71697ba9c.jpg",
            },
            {
              name: "helio",
              value: 40,
              brand_logo_url:
                "https://hdn-1.fynd.com/media/logo/brand/original/13995_d6059d098f8248c4a6f50ec151496137.jpg",
              brand_banner_url:
                "https://hdn-1.fynd.com/media/banner/brand/original/13996_ac2d726eca864ee8b82b180a68d94b1e.jpg",
              brand_banner_portrait_url:
                "https://hdn-1.fynd.com/media/banner_portrait/brand/original/13997_291169a4fb674d2295e033a71697ba9c.jpg",
            },
          ],
        },
      });
    }
    return ApiService.get(URLS.BRANDS(), {});
  },
  fetchMarketPlaceLogs(params) {
    if (isDev) {
      return Promise.resolve({
        data: {
          items: [
            {
              start_time: "2021-10-12T00:01:25.648Z",
              end_time: "2021-10-12T00:03:53.712Z",
              event_type: "SKU_UPDATES",
              trace_id: [
                "nykaa_inventory_syncer.c6306106-0b92-4fc0-b43f-d4bfa28c8933",
              ],
              count: 969,
              status: "in_progress",
            },
            {
              start_time: "2021-10-11T00:02:22.414Z",
              end_time: "2021-10-11T00:04:49.613Z",
              event_type: "INVENTORY_RESET_UPDATES",
              trace_id: [
                "nykaa_inventory_syncer.fc8d9916-fb17-4925-bd47-3c688e01cc31",
              ],
              count: 969,
              status: "pending",
            },
            {
              start_time: "2021-10-10T00:01:22.840Z",
              end_time: "2021-10-10T00:03:49.874Z",
              event_type: "FULL_INVENTORY_UPDATES",
              trace_id: [
                "nykaa_inventory_syncer.747bdbf6-9179-4031-b2c0-2caba3bac392",
              ],
              count: 969,
              status: "cancelled",
            },
            {
              start_time: "2021-10-09T00:01:24.259Z",
              end_time: "2022-06-22T03:03:51.907Z",
              event_type: "FULL_PRICE_UPDATES",
              trace_id: [
                "nykaa_inventory_syncer.e6a4ed22-2552-4ae7-8612-c740b6eca5ca",
              ],
              count: 969,
              status: "in_progress",
            },
            {
              start_time: "2021-10-12T00:01:25.648Z",
              end_time: "2021-10-12T00:03:53.712Z",
              event_type: "REALTIME_INVENTORY_DELETE_UPDATE",
              trace_id: [
                "nykaa_inventory_syncer.c6306106-0b92-4fc0-b43f-d4bfa28c8933",
              ],
              count: 969,
              status: "cancelled",
            },
            {
              start_time: "2021-10-12T00:01:25.648Z",
              end_time: "2021-10-12T00:03:53.712Z",
              event_type: "REALTIME_INVENTORY_UPDATE",
              trace_id: [
                "nykaa_inventory_syncer.c6306106-0b92-4fc0-b43f-d4bfa28c8933",
              ],
              count: 969,
              status: "cancelled",
            },
            {
              start_time: "2021-10-12T00:01:25.648Z",
              end_time: "2021-10-12T00:03:53.712Z",
              event_type: "REALTIME_PRICE_UPDATE",
              trace_id: [
                "nykaa_inventory_syncer.c6306106-0b92-4fc0-b43f-d4bfa28c8933",
              ],
              count: 969,
              status: "cancelled",
            },
          ],
          page: {
            type: "number",
            size: 10,
            current: 1,
            has_next: false,
            item_total: 4,
          },
        },
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        params,
      }
    );
    return ApiService.get(URLS.MARKETPLACE_LOGS(), axiosOption);
  },
  exportMarketPlaceLogs(body) {
    if (isDev) {
      return Promise.resolve({
        data: {
          job_id: "6166c4ffc62061342d739bab",
          status: "queued",
        },
      });
    }
    let axiosOption = Object.assign(
      {},
      {
        data: body,
      }
    );
    return ApiService.post(URLS.EXPORT_CSV(), axiosOption);
  },
  getExportLogsJobStatus(jobId) {
    if (isDev) {
      return Promise.resolve({
        data: {
          job_id: "6166c6dcc62061342d739bad",
          status: "success",
          download_url:
            "https://fynd-platform-extension-assets-staging.s3.us-east-2.amazonaws.com/platform-extensions/nykaa/log-export/inv-log-nykaa-2-FULL_INVENTORY_UPDATES.csv",
        },
      });
    }

    let axiosOption = {};
    return ApiService.get(URLS.EXPORT_JOB_STATUS(jobId), axiosOption);
  },
  subscribePlan(plan_id, body) {
    const options = {
      data: body,
    };
    return ApiService.post(URLS.SUBSCRIPTION(plan_id), options);
  },
  getSubscriptionPlan() {
    const options = {};
    return ApiService.get(URLS.SUBSCRIPTION(), options);
  },
  retryFPSync() {
    if (isDev) {
      return { FPSyncTriggered: true };
    }
    return ApiService.get(
      URLS.FP_SYNC(),
      {}
    );
  },
  updateSubscriptionStatus(subscription_id) {
    const options = {};
    return ApiService.post(URLS.SUBSCRIPTION_STATUS(subscription_id), options);
  },
  getPlans() {
    const options = {};
    return ApiService.get(URLS.PLANS(), options);
  },
};

export default MainService;
